@-webkit-keyframes fadeIn { 
    0% { opacity: 0; visibility: visible}
    90% { opacity: 0; visibility: visible}
    100% { opacity: 1; }
  }
  
@keyframes fadeIn {
    0% { opacity: 0; visibility: visible}
    90% { opacity: 0; visibility: visible}
    100% { opacity: 1; }
  }