* {
  font-family:  'MyFutura', Avenir-Light, Futura, Helvetica, Arial;
  font-size: 18px;
  color: #000000;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


body{
 background-color: #EBEEED;

/*
 background: url('./assets/about_office2.jpeg')center center fixed no-repeat;
-webkit-background-size: cover !important;
-moz-background-size: cover !important;
-o-background-size: cover !important;
background-size: cover !important;
*/
}


@font-face {
  font-family: 'MyFutura';
  src:  url('./fonts/35CA24_0_0.woff2') format('woff2'),
        url('./fonts/35CA24_0_0.woff') format('woff');
}

/* OVERLAY MENUS*/
.topleft{
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 30%;
  max-width: 300px;
  top: 60px;
  left: 60px;
  z-index: 100; /*Para que no lo tape nada*/
}
.topleft .sectionmenu
{

  overflow: hidden; /* just clearing floats */
  cursor: pointer;
  height: 24px;
  float: left;/*Esto hace que se apilen en linea o sobre el anterior, segun el ancho de la pagina*/
  text-align: left;

  font-family: Avenir-Light, MyFutura, Futura, Helvetica, Arial;
  font-size: 12px;
  color: #000000;
  letter-spacing: 1.05px;
  text-transform: uppercase;

  margin-top: 5px;
}

.topleft .sectionmenu:hover
{
  color: #ff4583;
}
.topright{
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 30%;
  max-width: 300px;
  top: 60px;
  right: 60px;
  z-index: 100; /*Para que no lo tape nada*/
}
.topright .sectionmenu
{

  overflow: hidden; /* just clearing floats */
  cursor: pointer;
  width: 100%; /* 1 items per row */
  height: 24px;
  float: right;/*Esto hace que se apilen en linea o sobre el anterior, segun el ancho de la pagina*/
  text-align: right;

  font-family: Avenir-Light, MyFutura, Futura, Helvetica, Arial;
  font-size: 12px;
  color: #000000;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}

.topright .sectionmenu:hover
{
  color: #ff4583;
}

.bottomleft{
  position: fixed;
  width: 40%;
  bottom: 60px;
  left: 60px;
  z-index: 100; /*Para que no lo tape nada*/
}
.bottomleft .sectionmenu
{

  overflow: hidden; /* just clearing floats */
  cursor: pointer;
  width: 80px; /* 1 items per row */
  height: 20px;
  float: left;/*Esto hace que se apilen en linea o sobre el anterior, segun el ancho de la pagina*/
  text-align: left;

  font-family: Avenir-Light, MyFutura, Futura, Helvetica, Arial;
  font-size: 12px;
  color: #000000;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}
.bottomleft .sectionmenu:hover
{
  color: #ff4583;
}

.bottomright{
  position: fixed;
  width: 40%;
  bottom: 60px;
  right: 60px;
  z-index: 100; /*Para que no lo tape nada*/
}
.bottomright .sectionmenu
{
  overflow: hidden; /* just clearing floats */
  width: 80px; /* 1 items per row */
  height: 20px;
  float: right;/*Esto hace que se apilen en linea o sobre el anterior, segun el ancho de la pagina*/
  text-align: right;

  font-family: Avenir-Light, MyFutura, Futura, Helvetica, Arial;
  font-size: 12px;
  color: #000000;
  letter-spacing: 1.05px;
  text-transform: uppercase;
}


/* CONTENEDOR*/
.general_centrado{ /*Este es el que queria que fuera absolute para usar scrolls de pagina, pero no lo uso aun */
  display: flex;
  position: relative;
  width:100%;
  height: 100vh; /*Como minimo, la altura tiene que ser el 100% vertical, para que no se vea el blog (en caso de haberlo)*/
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenido{
  position:relative;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%); /*http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/*/
  text-align: center;

  /*Para que al subir el maintext, no se blurree*/
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;

  /**flex**/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contenidoMobileBackground{
  background: none;
}


.contenido .maintext{
  
font-family: 'MyFutura', Avenir-Light, Helvetica, Arial;
font-size: 36px;
color: #000000;
letter-spacing: 3.8px;
text-transform: lowercase;
}

.contenido .subtext{
  position: relative;

    
  font-family: Avenir-Light, Helvetica, Arial;
  font-size: 20px;
  color: #000000;
  letter-spacing: 4.5px;
  text-transform: uppercase;
  max-width: 960px;

  padding-left: 20px;
  padding-right: 20px;
}

.contenido .smalltext{
  position: relative;

    
  font-family: "MyFutura", Avenir-Light, Helvetica, Arial;
  font-size: 13px;
  color: #000000;
  letter-spacing: 4.5px;
  text-transform: uppercase;
  max-width: 960px;

  padding-left: 20px;
  padding-right: 20px;
}

.sectionimage{
  width: calc(100% - 120px);
  height: calc(100vh - 300px);
  top: '150px';
  bottom: '150px';
  left: '60px';
  right: '60px';
}

.underline-yellow{
  font-family: inherit;
  font-size: inherit;
  text-decoration: underline;
  text-decoration-color: yellow;
  text-decoration-thickness: 8px;
}


/* MOBILE */
/* REAL MOBILE */
@media screen and (min-device-width: 160px) and ( max-width: 700px ){
.topleft{
  width: 200px;
  top: 40px;
  left: 20px;
}
.topright{
  width: 200px;
  top: 40px;
  right: 20px;
}
.bottomleft{
  bottom: 40px;
  left: 20px;
}
.bottomright{
  bottom: 40px;
  right: 20px;
}

.contenido{
  position: absolute;
  max-height: 200px;
  max-width: 92%;
}

.contenidoMobileBackground{
  background-color: rgba(243, 243, 243, 0.86);
}

.contenido .maintext{
  font-size: 28px;
}

.contenido .subtext{
  font-size: 13px;
}

.contenido .smalltext{
  font-size: 10px;
}


.sectionimage{
  width: 100%;
  height: 100vh;
  top: '150px';
  bottom: '150px';
}

}